import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { faPatreon, faReddit, faVk } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard, faEdit, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import {
  faCartShopping,
  faCheck,
  faCheckCircle,
  faExclamationTriangle,
  faEye,
  faKey,
  faList,
  faLock,
  faSortAmountDown,
  faSortAmountUp,
  faThLarge,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTrashRestoreAlt,
} from "@fortawesome/free-solid-svg-icons";

// Premium
library.add(faCreditCard);
library.add(faPatreon);
library.add(faCartShopping);
library.add(faLock);

// Login
library.add(faExclamationTriangle);

// Filter
library.add(faSortAmountDown);
library.add(faSortAmountUp);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faThLarge);
library.add(faList);
library.add(faReddit);
library.add(faVk);

// Game
library.add(faCheck);

// Articles
library.add(faEye);
library.add(faThumbsUp);
library.add(faTrashAlt);
library.add(faTrashRestoreAlt);
library.add(faEdit);

// Settings
library.add(faKey);
library.add(faTimes);

dom.watch();
