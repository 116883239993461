import cookies from "browser-cookies";
import $ from "cash-dom";

$(".banner-container").each(function () {
  const $container = $(this);
  const $closeButton = $container.find(".close-banner");
  const bannerId = $container.data("banner-id");

  $closeButton.on("click", function (e) {
    e.preventDefault();
    e.stopPropagation();

    $container.remove();

    if (bannerId) {
      cookies.set(bannerId, "true", { path: "/", expires: 30 });
    }
  });
});
