import axios from "axios";
import $ from "cash-dom";

import "./icons";
import "./lazyload";

// lazy load
import("./popup");
import("bootstrap/js/dist/collapse");
import("bootstrap/js/dist/alert");
import("bootstrap/js/dist/tab");

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

if (!$("body").data("auth")) {
  import("./login").then(({ default: Login }) => {
    window.Login = Login;
    $(document).on("click", ".popup__login__show", function () {
      Login.showWindow();
      return false;
    });
    document.body.addEventListener("showLoginWindow", () => {
      Login.showWindow();
    });
  });
}
