import axios from "axios";
import cookies from "browser-cookies";
import $ from "cash-dom";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

import "../styles/main.scss";
import "./common";
import "./lazyload";
import "./notifications";

const body = $("body");

if (body.data("auth")) {
  import("./premium");
}
if (body.data("admin")) {
  import("./admin");
}

// Store Firebase Token in Cookies
document.body.addEventListener("storePushNotificationsToken", () => {
  const firebaseConfig = {
    apiKey: "AIzaSyDnhrT75tXokfWpxWCYfce8NpeuTh_ylO0",
    authDomain: "psprices.firebaseapp.com",
    projectId: "psprices",
    storageBucket: "psprices.appspot.com",
    messagingSenderId: "1055506095860",
    appId: "1:1055506095860:web:8394ba9b5533a8768c08a1",
    measurementId: "G-2EKFTDKZBS",
  };
  const app = initializeApp(firebaseConfig);
  isSupported().then((isSupported) => {
    if (!isSupported) {
      return;
    }
    const messaging = getMessaging(app);
    getToken(messaging, { vapidKey: "BP7Kcu-v-HzSP9XEm-TvDI-on1IcDFjOJCM8vQT1t5rfplgll-4MsLDFX0uqcOEiDslCiVB8axy1pstKNDzU2dY" })
      .then((currentToken) => {
        cookies.set("push_token__firebase", currentToken, { path: "/", expires: 365 });
      })
      .catch(() => {
        // do nothing
      });
  });
});

import("./analytics").then(({ default: Analytics }) => {
  $("*[data-analytics]").on("click", function () {
    const event = $(this).data("analytics");
    void Analytics.logEvent(event);
  });
});

$(document).ready(function () {
  $("#filter_form_toggle").on("click", function () {
    $("#filter_form").toggleClass("hidden");
  });
  // Load game detailed page scripts
  if ($("#page--game-detail").length) {
    import("./pages/game");
  }
});

// Platform selector
$("[data-toggle-platform]").on("click", function () {
  const platform = $(this).data("toggle-platform");
  const current_url = window.location.href;
  const url = new URL(current_url);
  const current_platform = url.searchParams.get("platform");
  // current_platform is comma separated list of platforms
  // add or remove platform from list
  if (current_platform) {
    const platforms = current_platform.split(",");
    const index = platforms.indexOf(platform);
    if (index === -1) {
      platforms.push(platform);
    } else {
      platforms.splice(index, 1);
    }
    url.searchParams.set("platform", platforms.join(","));
  } else {
    url.searchParams.set("platform", platform);
  }
  // remove page param
  url.searchParams.delete("page");
  // navigate to new url
  window.location = url.toString();

  return false;
});

/**
 * Region selector
 */
$("#dropRegions").on("click", function () {
  const path = window.location.pathname + window.location.search;
  const holder = $("#regionsListHolder");
  if (holder.html() === "") {
    axios.get(`/fragment/regions/?path=${encodeURIComponent(path)}`).then((response) => {
      if (response.status === 200) {
        holder.html(response.data);
        if (window.lazyload) {
          window.lazyload.update();
        }
      }
    });
  }
  holder.toggleClass("hidden");
  $("html")[0].scrollTop = 0;
});

/**
 * Language selector
 */
$("#language-form a").on("click", function () {
  const language = $(this).data("language");
  if (!language) {
    return true;
  }
  const form = $(this).closest("form");
  form.find("input[name='language']").val(language);
  form[0].submit();
  return false;
});

/**
 * Login/Signup form username trim
 */
$("#id_username").on("blur", function () {
  const username = $(this).val();
  $(this).val(username.replace(/^\s*|\s*$/g, ""));
  return $(this).val();
});
